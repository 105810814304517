import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { menuPaths } from '../constants/menuPaths';
import { ReactComponent as ArrowLeft } from '../images/svgs/arrow-left-solid.svg';
import { ReactComponent as ArrowRight } from '../images/svgs/arrow-right-solid.svg';

import styles from '../styles/components/pageNavigation.module.scss';

const PageNavigation = ({ pageContext, basePath }) => {
  const { currentPage, language, numPages } = pageContext;
  console.log(menuPaths);
  console.log(language);
  const pagePath = `/${language}/${basePath[language]}`;

  const prevPage = currentPage - 1 > 1 ? currentPage - 1 : '';
  const nextPage = currentPage + 1 <= numPages ? currentPage + 1 : currentPage;
  let firstLink = currentPage < 3 ? '' : currentPage - 1;

  let firstButton = 1;
  let secondButton = 2;
  let thirdButton = 3;

  if (currentPage > 2 && currentPage < numPages - 1) {
    firstButton = currentPage - 1;
    secondButton = currentPage;
    thirdButton = currentPage + 1;
  }

  if (currentPage >= numPages - 1) {
    firstButton = numPages - 2;
    secondButton = numPages - 1;
    thirdButton = numPages;
  }

  return (
    <div className={styles.container}>
      <nav className={styles.navigation}>
        <ul>
          <li>
            <Link to={`${pagePath}/${prevPage}`}>
              <ArrowLeft />
            </Link>
          </li>
          {currentPage > 2 && (
            <li>
              <Link to={`${pagePath}`} activeClassName={styles.activeLink}>
                1
              </Link>
            </li>
          )}
          {currentPage > 3 && <div className={styles.dots}>. . .</div>}
          {numPages > 2 && (
            <>
              <li className={styles.link}>
                <Link
                  to={`${pagePath}/${firstLink}`}
                  activeClassName={styles.activeLink}
                >
                  {firstButton}
                </Link>
              </li>
              <li className={styles.link}>
                <Link
                  to={`${pagePath}/${secondButton}`}
                  activeClassName={styles.activeLink}
                >
                  {secondButton}
                </Link>
              </li>
              <li className={styles.link}>
                <Link
                  to={`${pagePath}/${thirdButton}`}
                  activeClassName={styles.activeLink}
                >
                  {thirdButton}
                </Link>
              </li>
            </>
          )}

          {currentPage < numPages - 2 && (
            <div className={styles.dots}>. . .</div>
          )}
          {currentPage < numPages - 1 && (
            <li>
              <Link
                to={`${pagePath}/${numPages}`}
                activeClassName={styles.activeLink}
              >
                {numPages}
              </Link>
            </li>
          )}
          <li className={styles.arrow}>
            <Link to={`${pagePath}/${nextPage}`}>
              <ArrowRight />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

PageNavigation.propTypes = {
  basePath: PropTypes.shape({
    en: PropTypes.string.isRequired,
    es: PropTypes.string.isRequired,
    it: PropTypes.string.isRequired,
    pt: PropTypes.string.isRequired,
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    skipVal: PropTypes.number.isRequired,
  }).isRequired,
};

export default PageNavigation;
