import React from 'react';
import { graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ContentWrapper from '../components/contentWrapper/contentWrapper';
import Layout from '../components/layout';
import MessageLJ from '../components/messageLJ/messageLJ';
import PageNavigation from '../components/pageNavigation';

import { menuPaths } from '../constants/menuPaths';
import TemplateMetadata from './templateMetadata';
import { ReactComponent as CoverArt } from '../images/svgs/corner-cover-pink.svg';

import styles from '../styles/templates/happinessCornerTemplate.module.scss';

export default function Template({ data, location, pageContext }) {
  let cards = null;
  console.log(pageContext);

  if (data.allMarkdownRemark != null) {
    const posts = data.allMarkdownRemark.edges;

    cards = posts.map(({ node }) => (
      <div
        className={styles.cardWrapper}
        key={`container-${node.frontmatter.id}`}
      >
        <iframe
          key={node.frontmatter.id}
          src={node.frontmatter.youTubeLink}
          title={node.frontmatter.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <div className={styles.decorationStrip}></div>
        <div className={styles.cardText}>{node.frontmatter.previewText}</div>
      </div>
    ));
  } else {
    cards = (
      <div className={styles.emptyQuery}>
        <FormattedMessage id="category.emptyQuery" />
      </div>
    );
  }

  return (
    <Layout location={location}>
      <TemplateMetadata page="happinessCorner" />
      <ContentWrapper isPageWrapper>
        <div className={styles.container}>
          <div className={styles.coverContainer}>
            <CoverArt />
            <h1 className={styles.titleSection}>
              <FormattedMessage id="home.buttons.corner" />
            </h1>
          </div>
          <div className={styles.cardsContainer}>{cards}</div>
          {pageContext.numPages > 1 && (
            <PageNavigation
              pageContext={pageContext}
              basePath={menuPaths.happinessCorner}
            />
          )}
          <MessageLJ />
        </div>
      </ContentWrapper>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    skipVal: PropTypes.number.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query happinessCornerQuery($language: String!, $skipVal: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { language: { eq: $language }, youTubeLink: { ne: null } }
      }
      limit: $limit
      skip: $skipVal
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            date
            previewText
            youTubeLink
          }
        }
      }
    }
  }
`;
