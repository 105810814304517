import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const TemplateMetadata = ({ intl, page }) => {
  return (
    <Helmet>
      <title>{intl.formatMessage({ id: `menu.${page}` })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: `meta.description.${page}` })}
      />
      <meta
        property="og:title"
        content={intl.formatMessage({ id: `menu.${page}` })}
      />
      <meta
        property="og:description"
        content={intl.formatMessage({ id: `meta.description.${page}` })}
      />
    </Helmet>
  );
};

TemplateMetadata.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.string.isRequired,
};

export default injectIntl(TemplateMetadata);
